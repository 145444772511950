import React from "react";
import HeroSection from "../components/homepage/hero";
import GenerateResume from "../components/homepage/generate_resume";
import HowItWorks from "../components/homepage/how_it_works";
import PricingSection from "../components/homepage/pricing_section.js";
import Footer from "../components/footer";
import FAQPage from "../../src/pages/faq.js";
import LinkedInPostMockup from "../components/homepage/linkedin_comment.js";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import FeatureHighlights from "../components/homepage/feature_highlights.js";


import {
  Box,
  CircularProgress,
  useTheme,
  Button,
  Typography,
} from "@mui/material";

const Homepage = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const theme = useTheme();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress
          size={60}
          thickness={4}
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </Box>
    );
  }
  // Redirect if authenticated
  if (isAuthenticated) {
    return <Navigate to="/dashboard-layout" />;
  }

  return (
    <div>
      <HeroSection />
      <PricingSection />
      <FAQPage />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 6,
          px: 3,
          bgcolor: theme.palette.background.paper,
          borderRadius: "12px",
          maxWidth: "900px",
          margin: "0 auto",
          mt: 8,
          mb: 12,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 4,
            textAlign: "center",
            color: theme.palette.primary.dark,
            fontWeight: "700",
            fontSize: { xs: "2.2rem", md: "3rem" },
            letterSpacing: "0.5px",
            textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
          }}
        >
          Act Now! Secure Interview Calls in Just 2 Weeks
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 6,
            textAlign: "center",
            color: theme.palette.text.secondary,
            fontSize: { xs: "1rem", md: "1.4rem" },
            maxWidth: "800px",
            margin: "0 auto",
            lineHeight: 1.7,
            px: { xs: 2, sm: 4 },
          }}
        >
          Time is running out—don't miss your chance to land your dream job.
          Enroll today and supercharge your job search!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => loginWithRedirect()}
          sx={{
            fontWeight: "600",
            px: 5,
            py: 1.75,
            fontSize: "1.2rem",
            borderRadius: "50px",
            textTransform: "none",
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
            mt: 4,
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Get Started Now"
          )}
        </Button>
      </Box>
      <Footer />
    </div>
  );
};

export default Homepage;
