import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import Skeleton from '@mui/material/Skeleton';

import registrationVideo from '../demos/registration.webm';
import aiResumeVideo from '../demos/ai_resume.webm';
import autoApplyVideo from '../demos/auto_apply.webm';

const PREFIX = 'HowExtensionWorks';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  header: `${PREFIX}-header`,
  videoContainer: `${PREFIX}-videoContainer`,
  stepContainer: `${PREFIX}-stepContainer`,
  content: `${PREFIX}-content`,
  textContainer: `${PREFIX}-textContainer`,
  divider: `${PREFIX}-divider`,
  linkContainer: `${PREFIX}-linkContainer`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.mainContainer}`]: {
    marginTop: theme.spacing(4),
  },
  [`& .${classes.header}`]: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Georgia, serif',
  },
  [`& .${classes.videoContainer}`]: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.linkContainer}`]: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
}));

const HowExtensionWorks = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current.forEach((videoElement, index) => {
      if (videoElement) {
        videoElement.addEventListener('loadeddata', () => handleVideoLoad(index));
        videoElement.addEventListener('error', () => handleError(index));
      }
    });

    return () => {
      videoRefs.current.forEach((videoElement, index) => {
        if (videoElement) {
          videoElement.removeEventListener('loadeddata', () => handleVideoLoad(index));
          videoElement.removeEventListener('error', () => handleError(index));
        }
      });
    };
  }, []);

  const handleVideoLoad = (index) => {
    console.log(`Video ${index + 1} has loaded`);
    setLoading(false);
  };

  const handleError = (index) => {
    console.log(`Error loading video ${index + 1}`);
    setLoading(false);
  };

  const videos = [
    { src: registrationVideo, title: 'Registration Process' },
    { src: aiResumeVideo, title: 'Chrome Extension to generate resumes with one click' },
    { src: autoApplyVideo, title: 'Chrome Extension to auto apply to jobs with one click' },
  ];

  return (
    <StyledContainer className={classes.mainContainer}>
      <Box className={classes.linkContainer} sx={{ marginBottom: theme.spacing(2) }}>
        <Typography variant="h6" component="p" sx={{ fontFamily: 'Georgia, serif', color: theme.palette.text.secondary, marginRight: theme.spacing(1) }}>
          Click here to add the Careerfornia extension to your browser:
        </Typography>
        <Link href="https://chromewebstore.google.com/detail/careerfonia/ampdncancbacehgbghpfhgfllclcinnp" target="_blank" rel="noopener" sx={{ fontFamily: 'Georgia, serif', color: theme.palette.primary.main, textDecoration: 'none', fontSize: '1.2rem' }}>
          Careerfornia Chrome Extension
        </Link>
      </Box>
      {videos.map((video, index) => (
        <div key={index} className={classes.videoContainer}>
          <Typography variant="h5" sx={{ marginBottom: theme.spacing(2), fontFamily: 'Georgia, serif', color: theme.palette.primary.main }}>
            {video.title}
          </Typography>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <video
              ref={el => (videoRefs.current[index] = el)}
              width="100%"
              height="auto"
              controls
              muted
              preload="metadata"
              onLoadedData={() => handleVideoLoad(index)}
              onError={() => handleError(index)}
              onVolumeChange={(e) => {
                if (e.target.muted === false) {
                  e.target.muted = true;
                }
              }}
            >
              <source src={video.src} type="video/mp4" />
              <Typography variant="body2" color="error">
                Sorry, this video cannot be played.
              </Typography>
            </video>
          </motion.div>
        </div>
      ))}
      <Box sx={{ textAlign: 'center', marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
        <Typography variant="body1" sx={{ fontFamily: 'Georgia, serif', color: theme.palette.text.primary }}>
          You may occasionally need to input certain fields that the autopilot feature does not initially recognize. Once these fields are provided, AI will remember and utilize this information automatically in subsequent uses, requiring no further input from you.
        </Typography>
      </Box>
    </StyledContainer>
  );
};

export default HowExtensionWorks;
