import React, { useEffect, useState, lazy, Suspense } from "react";
import { Box, Typography, useTheme, IconButton, Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Lottie from "react-lottie";
import autoPilotAnimation from "../../lottie/auto-pilot.json";
import linkedinCommentAnimation from "../../lottie/linkedin-comment.json";
import resumeAnimation from "../../lottie/resume.json";
import "../../styles/components/homepage/feature_highlights.css";
import CompanyLogos from "./CompanyLogos";

import aiResumeVideo from '../../demos/ai_resume.webm';
import autoApplyVideo from '../../demos/auto_apply.webm';

// Import poster images
import aiResumePoster from '../../demos/ai_resume.png';
import autoApplyPoster from '../../demos/ai_resume.png';

const LazyVideo = lazy(() => import('./LazyVideo'));

// High-Quality SEO additions
const pageTitle = "Resume Builder Free | Best AI Resume Maker for Job Applications";
const pageDescription = "Create the best resume for job applications with our free AI resume builder. Boost your job search and apply to jobs with ease. Try it now!";

const videos = [
  {
    src: aiResumeVideo,
    heading: "AI-Powered Resume Generation",
    description: "Watch how our AI creates resumes tailored for each job application, making you the ideal candidate for every job.",
    poster: aiResumePoster,  // Add poster property
  },
  {
    src: autoApplyVideo,
    heading: "Automated Job Application Process",
    description: "See our AI in action as it automatically applies to multiple job postings, saving you time and effort in your job search.",
    poster: autoApplyPoster, // Add poster property
  },
];

const HeroSection = () => {
  const theme = useTheme();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.title = pageTitle;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", pageDescription);
    }
  }, []);

  const handleNextVideo = () => {
    setVideoLoading(true);
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePreviousVideo = () => {
    setVideoLoading(true);
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
    );
  };

  const handleVideoLoaded = () => {
    console.log("Video has loaded");
    setVideoLoading(false);
  };

  const textVariants = {
    offscreen: { y: -30, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 7,
        delay: 0.2,
      },
    },
  };

  return (
    <section
      id="hero"
      className="hero-section"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(4),
          textAlign: "center",
          overflow: "hidden",
          position: "relative",
          "@media (max-width:600px)": {
            padding: theme.spacing(2),
            minHeight: "auto",
          },
          paddingTop: theme.spacing(8),
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.4rem",
              md: "1.6rem",
              lg: "1.8rem",
            },
            mb: 3,
            color: theme.palette.primary.dark,
            fontFamily: "Georgia, serif",
            fontWeight: "bold",
          }}
        >
          Get Interview Calls in Less Than 2 Weeks from Top Companies
        </Typography>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            background: theme.palette.primary.main,
            clipPath: "polygon(0 60%, 100% 30%, 100% 100%, 0 100%)",
          }}
        />

        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={textVariants}
        >
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.4rem",
                sm: "1.6rem",
                md: "1.8rem",
                lg: "2.2rem",
              },
              mb: 3,
              color: theme.palette.text.primary,
              fontFamily: "Georgia, serif",
            }}
          >
            {videos[currentVideoIndex].heading}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1.1rem",
                lg: "1.2rem",
              },
              mb: 2,
              color: theme.palette.text.secondary,
              fontFamily: "Arial, sans-serif",
            }}
          >
            {videos[currentVideoIndex].description}
          </Typography>
        </motion.div>

        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            position: "relative",
            zIndex: 1,
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width:900px)": {
              flexDirection: "column",
            },
          }}
        >
          <IconButton
            onClick={handlePreviousVideo}
            sx={{
              position: "absolute",
              left: { xs: "2%", md: "5%", lg: "10%" },
              top: "50%",
              transform: "translateY(-50%) scale(1.1)", // Combined transforms
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              zIndex: 1,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <FaArrowLeft />
          </IconButton>

          <Box
            sx={{
              position: "relative",
              width: "100%",
              maxWidth: { xs: "90vw", sm: "80vw", md: "70vw" },
              height: { xs: "40vh", sm: "50vh", md: "60vh", lg: "70vh" },
              borderRadius: "15px",
              overflow: "hidden",
            }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {videoLoading && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ borderRadius: "15px" }}
              />
            )}
            <Suspense fallback={<Skeleton variant="rectangular" width="100%" height="100%" />}>
              <LazyVideo
                src={videos[currentVideoIndex].src}
                poster={videos[currentVideoIndex].poster} // Pass the poster prop
                style={{
                  width: "100%",
                  height: "100%",
                  display: videoLoading ? "none" : "block",
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
                onLoadedData={handleVideoLoaded}
              />
            </Suspense>
          </Box>

          <IconButton
            onClick={handleNextVideo}
            sx={{
              position: "absolute",
              right: { xs: "2%", md: "5%", lg: "10%" },
              top: "50%",
              transform: "translateY(-50%) scale(1.1)", // Combined transforms
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              zIndex: 1,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <FaArrowRight />
          </IconButton>
        </Box>

        {/* Company Logo Section */}
        <CompanyLogos />

        {/* Rest of your component */}
      </Box>
    </section>
  );
};

export default HeroSection;
