import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../styles/onboard_user.css';
import { baseurl, upload_resume, save_user_details } from '../util/endpoints.js';
import { useAuth0 } from '@auth0/auth0-react';
import Lottie from 'react-lottie';
import processingAnimation from '../lottie/processing_resume.json';
import { Navigate } from 'react-router-dom';
import { Box, CircularProgress, useTheme, Typography, Button, Container, Paper, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const stepsTitles = ['Upload Resume', 'Add Job Preferences', 'Start Applying'];

const OnboardUser = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [agreeResponsibility, setAgreeResponsibility] = useState(false);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setSelectedFile(acceptedFiles[0]);
        }
    });

    useEffect(() => {
        let timer;
        if (isUploading) {
            timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            setElapsedTime(0);
        }
        return () => clearInterval(timer);
    }, [isUploading]);

    const handleResumeUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadFile = async (file, userid) => {
        if (!agreeTerms || !agreeResponsibility) {
            setError('Please agree to the terms and conditions and responsibility statement to continue.');
            return;
        }
        setIsUploading(true);
        const formData = new FormData();
        formData.append('resume_file', file);
        formData.append('userid', userid);

        try {
            const token = await getAccessTokenSilently();
            const endpoint = baseurl + upload_resume;
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                navigate('/resume-page', { state: { resumeData: data } });
            } else {
                throw new Error('Error uploading file. Please try again.');
            }
        } catch (error) {
            setError('Error uploading file. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleUploadClick = () => {
        if (user && selectedFile) {
            const userid = user.sub;
            uploadFile(selectedFile, userid);
        } else if (!user) {
            setError('User details are not available yet. Please try again in a few seconds.');
        } else {
            setError('Please select a file to upload.');
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            sendUserDetailsToBackend();
        }
    }, [isAuthenticated, getAccessTokenSilently, user]);

    const sendUserDetailsToBackend = async () => {
        try {
            const token = await getAccessTokenSilently();
            const endpoint = baseurl + save_user_details;
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    username: user.nickname,
                    userid: user.sub
                }),
            });

            if (!response.ok) {
                const errorBody = await response.json();
                if (response.status === 400) {
                    setError('Required fields are missing. Please complete your profile.');
                } else if (response.status === 500 && errorBody.error.includes('Validation error')) {
                    setError('User not onboarded yet.');
                } else {
                    setError('An unexpected error occurred. Please try again later.');
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            if (!error.message.includes('Validation error')) {
                setError('An unexpected error occurred. Please try again later.');
            }
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: processingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleManualEntry = () => {
        if (!agreeTerms || !agreeResponsibility) {
            setError('Please agree to the terms and conditions and responsibility statement to continue.');
            return;
        }
        navigate('/resume-page', { state: { manualEntry: true } });
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress
                    size={60}
                    thickness={4}
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Container maxWidth="md" className="get-started-container">
            <Box className="progress-bar" sx={{ mb: 4 }}>
                {stepsTitles.map((title, index) => (
                    <Box key={title} className="step-container">
                        <Box className={`step ${index === 0 ? 'active-step' : ''}`}>
                            {index + 1}
                        </Box>
                        <Typography variant="body2" className="step-title">{title}</Typography>
                    </Box>
                ))}
            </Box>

            <Paper elevation={0} className="upload-section" sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>Upload Your Resume for Auto Parsing</Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>Our advanced AI will automatically extract and organize your professional details</Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>You can use your existing resume, do not worry if its not perfect. We are here to make it perfect.</Typography>
                <Box {...getRootProps()} className="dropzone" sx={{ 
                    border: `2px dashed ${theme.palette.primary.main}`,
                    borderRadius: 2,
                    p: 3,
                    mb: 2,
                    textAlign: 'center'
                }}>
                    <input {...getInputProps()} />
                    <Typography variant="body1">
                        {isDragActive ? "Drop the files here ..." : "Drag 'n' drop your resume here, or click to select file"}
                    </Typography>
                </Box>
                {selectedFile && (
                    <Typography variant="body2" className="selected-file-name" sx={{ mb: 2 }}>
                        Selected file: {selectedFile.name}
                    </Typography>
                )}
                <Tooltip title={!selectedFile || !agreeTerms || !agreeResponsibility ? "Upload resume and agree to the below" : ""}>
                    <span>
                        <Button
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={handleUploadClick}
                            disabled={!selectedFile || isUploading || !agreeTerms || !agreeResponsibility}
                            sx={{ mb: 2 }}
                        >
                            Upload your resume
                        </Button>
                    </span>
                </Tooltip>
                <Typography variant="caption" display="block" sx={{ mb: 2 }}>as .pdf or .docx file</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Or, if you prefer:
                    <Box
                        component="span"
                        onClick={handleManualEntry}
                        sx={{
                            ml: 1,
                            color: 'primary.main',
                            cursor: 'pointer',
                            transition: 'color 0.3s',
                            '&:hover': {
                                color: 'primary.dark',
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        Manually Add Your Details
                    </Box>
                </Typography>
                {isUploading && (
                    <Box className="processing-info" sx={{ mt: 3 }}>
                        <Box className="processing-animation">
                            <Lottie options={defaultOptions} height={200} width={200} />
                        </Box>
                        <Typography variant="body1" sx={{ mb: 1 }}>Processing your resume. It may take about a minute.</Typography>
                        <Typography variant="body2">Time elapsed: {elapsedTime} seconds</Typography>
                    </Box>
                )}
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Checkbox
                            checked={agreeTerms}
                            onChange={(e) => setAgreeTerms(e.target.checked)}
                            color="primary"
                            sx={{ mr: 1, p: 0 }}
                        />
                        By continuing, you agree to our{' '}
                        <Link to="/privacy-policy" style={{ color: theme.palette.primary.main, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                            Privacy Policy
                        </Link>
                        {' '}and{' '}
                        <Link to="/terms" style={{ color: theme.palette.primary.main, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                            Terms and Conditions
                        </Link>
                        .
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            checked={agreeResponsibility}
                            onChange={(e) => setAgreeResponsibility(e.target.checked)}
                            color="primary"
                            sx={{ mr: 1, p: 0 }}
                        />
                        By continuing, you agree that you are solely responsible for the applications and resumes you submit. Also note that AI models might make mistakes, so please review the details before submitting.
                    </Typography>
                </Box>
                {error && (
                    <Typography variant="body2" sx={{ color: 'error.main', mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Paper>
        </Container>
    );
};

export default OnboardUser;
