import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(4),
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const PrivacyPolicy = () => {
  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last Updated: October 15, 2024
      </Typography>

      <Typography variant="body1" paragraph>
        At Careerfornia ("we," "us," or "our"), we are committed to protecting your personal information and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services (collectively, the "Services"). By accessing or using our Services, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not access the Services.
      </Typography>

      <SectionHeading variant="h5">1. Information We Collect</SectionHeading>
      <Typography variant="body1" paragraph>
        We collect various types of information to provide and improve our Services to you.
      </Typography>

      <Typography variant="h6">1.1. Personal Information</Typography>
      <Typography variant="body1" paragraph>
        Information You Provide to Us:
      </Typography>
      <ul>
        <li>Registration Data: When you create an account, we collect your name, email address, password, and other registration details.</li>
        <li>Profile Information: Information you add to your profile, such as resume details, employment history, education, skills, and job preferences.</li>
        <li>Communication Data: Records of your correspondence with us, including support requests and feedback.</li>
      </ul>

      <Typography variant="h6">1.2. Usage Data</Typography>
      <Typography variant="body1" paragraph>
        We automatically collect information about how you access and use the Services, including:
      </Typography>
      <ul>
        <li>Device Information: IP address, browser type, operating system, and device identifiers.</li>
        <li>Log Data: Dates and times of access, pages viewed, links clicked, and other actions taken within the Services.</li>
      </ul>

      <Typography variant="h6">1.3. Cookies and Similar Technologies</Typography>
      <Typography variant="body1" paragraph>
        We use cookies, web beacons, and other tracking technologies to collect information about your browsing activities. For more details, see Section 4.
      </Typography>

      <SectionHeading variant="h5">2. How We Use Your Information</SectionHeading>
      <Typography variant="body1" paragraph>
        We use your information for various purposes, including:
      </Typography>

      <Typography variant="h6">2.1. To Provide and Maintain Our Services</Typography>
      <ul>
        <li>Account Management: To create and manage your account.</li>
        <li>Service Delivery: To facilitate the creation of resumes, job searches, and other career development tools.</li>
      </ul>

      <Typography variant="h6">2.2. To Improve and Personalize Your Experience</Typography>
      <ul>
        <li>Customization: To tailor content and recommendations based on your preferences and usage patterns.</li>
        <li>Analytics: To analyze usage trends and measure the effectiveness of our Services.</li>
      </ul>

      <Typography variant="h6">2.3. To Communicate with You</Typography>
      <ul>
        <li>Customer Support: To respond to your inquiries and provide assistance.</li>
        <li>Notifications: To send you updates, security alerts, and administrative messages.</li>
      </ul>

      <Typography variant="h6">2.4. For Legal and Security Purposes</Typography>
      <ul>
        <li>Compliance: To comply with legal obligations and regulatory requirements.</li>
        <li>Protection: To detect, prevent, and address fraud, security breaches, or other harmful activities.</li>
      </ul>

      <SectionHeading variant="h5">3. Disclosure of Your Information</SectionHeading>
      <Typography variant="body1" paragraph>
        We may share your information in the following circumstances:
      </Typography>

      <Typography variant="h6">3.1. With Service Providers</Typography>
      <Typography variant="body1" paragraph>
        We engage third-party companies to perform services on our behalf, such as:
      </Typography>
      <ul>
        <li>Cloud Hosting: Data storage and hosting services.</li>
        <li>Analytics Providers: Services that help us analyze usage patterns.</li>
        <li>Customer Support: Platforms that assist with communication and support.</li>
      </ul>
      <Typography variant="body1" paragraph>
        These service providers have access to your personal information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.
      </Typography>

      <Typography variant="h6">3.2. For Legal Reasons</Typography>
      <Typography variant="body1" paragraph>
        We may disclose your information:
      </Typography>
      <ul>
        <li>Compliance with Laws: If required by law, regulation, or legal process.</li>
        <li>Protection of Rights: To protect the rights, property, or safety of Careerfornia, our users, or others.</li>
      </ul>

      <Typography variant="h6">3.3. Business Transfers</Typography>
      <Typography variant="body1" paragraph>
        In the event of a merger, acquisition, or asset sale, your personal information may be transferred. We will notify you before your personal information becomes subject to a different Privacy Policy.
      </Typography>

      <SectionHeading variant="h5">4. Cookies and Tracking Technologies</SectionHeading>
      <Typography variant="h6">4.1. What Are Cookies?</Typography>
      <Typography variant="body1" paragraph>
        Cookies are small data files stored on your device that help us improve our Services and your experience.
      </Typography>

      <Typography variant="h6">4.2. Types of Cookies We Use</Typography>
      <ul>
        <li>Essential Cookies: Necessary for the operation of the Services.</li>
        <li>Performance Cookies: Collect information about how you use the Services.</li>
        <li>Functionality Cookies: Remember your preferences and personalize content.</li>
        <li>Analytics Cookies: Help us understand user behavior and improve the Services.</li>
      </ul>

      <Typography variant="h6">4.3. Managing Cookies</Typography>
      <Typography variant="body1" paragraph>
        You can set your browser to refuse cookies or alert you when cookies are being sent. However, some features of the Services may not function properly without cookies.
      </Typography>

      <SectionHeading variant="h5">5. Third-Party Services and AI Models</SectionHeading>
      <Typography variant="h6">5.1. Third-Party Applications</Typography>
      <Typography variant="body1" paragraph>
        We use third-party applications solely to provide our Services. These applications may have access to your personal information as necessary to perform their functions but are prohibited from using it for other purposes.
      </Typography>

      <Typography variant="h6">5.2. AI Models from Service Providers</Typography>
      <Typography variant="body1" paragraph>
        Our Services incorporate AI models from various service providers to enhance functionality. While we strive to select reputable providers, we are not responsible for the data handling practices of these third parties. We encourage you to review their privacy policies.
      </Typography>

      <SectionHeading variant="h5">6. Data Security</SectionHeading>
      <Typography variant="h6">6.1. Security Measures</Typography>
      <Typography variant="body1" paragraph>
        We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. These measures include:
      </Typography>
      <ul>
        <li>Encryption: Data encryption during transmission and storage.</li>
        <li>Access Controls: Restricted access to personal information.</li>
        <li>Monitoring: Regular security assessments and monitoring.</li>
      </ul>

      <Typography variant="h6">6.2. No Guarantee</Typography>
      <Typography variant="body1" paragraph>
        While we strive to protect your information, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security.
      </Typography>

      <SectionHeading variant="h5">7. Data Retention</SectionHeading>
      <Typography variant="body1" paragraph>
        We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </Typography>

      <SectionHeading variant="h5">8. Your Privacy Rights</SectionHeading>
      <Typography variant="h6">8.1. Access and Correction</Typography>
      <Typography variant="body1" paragraph>
        You have the right to access, update, or delete your personal information. You can do so by logging into your account settings or contacting us.
      </Typography>

      <Typography variant="h6">8.2. Opt-Out</Typography>
      <ul>
        <li>Marketing Communications: You may opt out of receiving promotional emails by following the unsubscribe instructions in those emails.</li>
        <li>Cookies: You can manage cookies through your browser settings.</li>
      </ul>

      <Typography variant="h6">8.3. European Economic Area (EEA) Residents</Typography>
      <Typography variant="body1" paragraph>
        If you are a resident of the EEA, you have additional rights under the General Data Protection Regulation (GDPR), including the right to:
      </Typography>
      <ul>
        <li>Data Portability: Receive a copy of your personal data in a structured, commonly used format.</li>
        <li>Restriction of Processing: Request the restriction of processing your personal data under certain conditions.</li>
        <li>Objection: Object to the processing of your personal data.</li>
      </ul>
      <Typography variant="body1" paragraph>
        To exercise these rights, please contact us at <Link href="mailto:support@careerfornia.com">support@careerfornia.com</Link>.
      </Typography>

      <SectionHeading variant="h5">9. Children's Privacy</SectionHeading>
      <Typography variant="body1" paragraph>
        Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected such information, we will take steps to delete it promptly.
      </Typography>

      <SectionHeading variant="h5">10. International Data Transfers</SectionHeading>
      <Typography variant="body1" paragraph>
        Your information may be transferred to—and maintained on—servers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ. By providing your information, you consent to such transfers.
      </Typography>

      <SectionHeading variant="h5">11. Updates to This Policy</SectionHeading>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time. Changes will be effective immediately upon posting the revised policy. We will notify you of significant changes by email or through a notice on our website. We encourage you to review this Privacy Policy periodically.
      </Typography>

      <SectionHeading variant="h5">12. Contact Us</SectionHeading>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </Typography>
      <Typography variant="body1">
        Email: <Link href="mailto:support@careerfornia.com">support@careerfornia.com</Link>
      </Typography>
      <Typography variant="body1">
        Address: Careerfornia, Hyderabad, Telangana, India
      </Typography>
    </StyledContainer>
  );
};

export default PrivacyPolicy;