import React, { useState, useEffect } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Typography, Grid, Skeleton, IconButton, useTheme, useMediaQuery, Link, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from './pages/dashboard';
import Pricing from './components/homepage/pricing';
import JobsApplied from './components/dashboard/jobs_applied';
import ReportIssue from './components/dashboard/report_issue';
import TailoredResume from './components/dashboard/tailored_resume';
import FAQPage from './pages/faq';
import Support from './components/dashboard/support';
import Announcements from './components/dashboard/announcements';
import CareerHacks from './components/career_hacks';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseurl, is_user_onboarded, check_credits } from './util/endpoints';
import GeneratedResumes from './components/dashboard/generated_resumes';
import ResumePage from './pages/resume_page';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HowExtensionWorks from './pages/how_extension_works';

const drawerWidth = 240;
const topNavBarHeight = 64;

const DashboardLayout = () => {
    const [selectedItem, setSelectedItem] = useState('dashboard');
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [usagecredits, setUsagecredits] = useState({
        auto_apply_credits: 0,
        auto_apply_limit: 0,
        ai_resume_credits: 0,
        ai_resume_limit: 0,
        ai_post_comment_credits: 0,
        ai_post_comment_limit: 0,
    });
    const [aiResumeGenerationCount, setAiResumeGenerationCount] = useState({ generated: 2, limit: 10 });
    const navigate = useNavigate();
    const userid = localStorage.getItem('userid');
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isOnboarded, setIsOnboarded] = useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (isAuthenticated) {
            const fetchUsagecredits = async () => {
                if (userid) {
                    setLoading(true);
                    const token = await getAccessTokenSilently();
                    const endpoint = baseurl + check_credits;
                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userid: userid }),
                    });
                    const data = await response.json();
                    if (data.status === 'success') {
                        setUsagecredits({
                            auto_apply_credits: data.data.auto_apply_credits,
                            auto_apply_limit: data.data.auto_apply_limit,
                            ai_resume_credits: data.data.ai_resume_credits,
                            ai_resume_limit: data.data.ai_resume_limit,
                            ai_post_comment_credits: data.data.ai_post_comment_credits,
                            ai_post_comment_limit: data.data.ai_post_comment_limit,
                        });
                        setLoading(false);
                    }
                }
            };

            fetchUsagecredits();
        }
    }, [user]);

    useEffect(() => {
        if (isAuthenticated) {
            const checkOnboardedStatus = async () => {
                try {
                    const token = await getAccessTokenSilently();
                    const url = baseurl + is_user_onboarded;
                    const response = await axios.post(url, { userid: userid },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        });
                    setIsOnboarded(response.data.is_onboarded);
                    setLoading(false);
                } catch (error) {
                    console.error('Error checking onboarding status:', error);
                    setLoading(false);
                }
            };
            checkOnboardedStatus();
        }
    }, [userid, navigate]);

    if (loading) {
        if (isAuthenticated) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
                    <Skeleton variant="rectangular" width={210} height={118} animation="wave" />
                    <Skeleton variant="text" width={210} height={40} animation="wave" sx={{ mt: 2 }} />
                    <Skeleton variant="text" width={210} height={40} animation="wave" sx={{ mt: 1 }} />
                </Box>
            );
        }
    }

    const renderContent = () => {
        switch (selectedItem) {
            case 'dashboard':
                return <Dashboard />;
            case 'jobs-applied':
                return <JobsApplied />;
            case 'generated-resumes':
                return <GeneratedResumes />;
            case 'pricing':
                return <Pricing />;
            case 'generate-ai-resume':
                return <TailoredResume />;
            case 'purchase-credit':
                return <Pricing />;
            case 'faq':
                return <FAQPage />;
            case 'support':
                return <Support />;
            case 'announcements':
                return <Announcements />;
            case 'career-hacks':
                return <CareerHacks />;
            case 'how-extension-works':
                return <HowExtensionWorks />;
            default:
                return <Dashboard />;
        }
    };

    if (isLoading) return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <CircularProgress 
                size={60}
                thickness={4}
                sx={{
                    color: theme.palette.primary.main,
                }}
            />
        </Box>
    );

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {isMobile && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, ...(isMobile ? { display: 'block' } : { display: 'none' }) }}>
                        <MenuIcon />
                    </IconButton>
                )}
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            mt: `${topNavBarHeight}px`,
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        zIndex: theme.zIndex.drawer
                    }}
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isMobile ? mobileOpen : true}
                    onClose={handleDrawerToggle}
                >
                    <List sx={{ overflowY: 'auto', flex: 1 }}>
                        {['Dashboard', 'Jobs Applied', 'Generated Resumes', 'Purchase Credit', 'FAQ', 'Support', 'Career Hacks', 'How Extension Works'].map((text) => (
                            <ListItem
                                button
                                key={text}
                                onClick={() => setSelectedItem(text.toLowerCase().replace(/\s/g, '-'))}
                                sx={{
                                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                    fontFamily: 'Georgia, serif',
                                    margin: '0 auto',
                                    textAlign: 'start',
                                    fontWeight: 'bold',
                                    marginBottom: '16px',
                                }}
                            >
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>

                    <Grid item xs={4} sx={{ textAlign: 'center', paddingBottom: '20px', position: 'relative' }}>

                        <Typography
                            variant="body2"
                            sx={{
                                mt: 1,
                                cursor: 'pointer',
                                backgroundColor: 'lightblue',
                                padding: '10px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => window.open('https://chromewebstore.google.com/detail/careerfonia/ampdncancbacehgbghpfhgfllclcinnp', '_blank', 'noopener')}
                        >
                            🎉 Download Extension 🎉
                        </Typography>
                    </Grid>

                    <Box
                        sx={{
                            mt: 'auto',
                            mb: 10,
                            px: 2,
                            py: 1,
                            backgroundColor: 'background.default',
                            borderTop: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Typography variant="body2">
                                    Linkedin Auto Apply
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">
                                    {usagecredits.auto_apply_credits}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    AI Resume
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right', mt: 1 }}>
                                <Typography variant="body2">
                                    {usagecredits.ai_resume_credits}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    AI Post Comments
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right', mt: 1 }}>
                                <Typography variant="body2">
                                    {usagecredits.ai_post_comment_credits}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        marginTop: `${topNavBarHeight}px`,
                    }}
                >
                    {renderContent()}
                </Box>
            </Box>
        </>
    );
};

export default DashboardLayout;