import React from 'react';
import { Box, Typography, Card, CardContent, Button, Grid, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

const plans = [
    {
        id: 0,
        name: 'Welcome',
        resumes: 2,
        autoApply: 5,
        comments: 10,
        price: 'Free (no credit card needed)',
        features: [
            '5 Resume Generations',
            '10 Auto Applies',
            '10 AI Post Comments'
        ],
        description: 'Get started with our free plan. Generate 2 tailored resumes, apply for 5 jobs with one click, and engage on LinkedIn with 10 AI-generated comments.',
        highlights: [
            'Perfect for beginners to test our services.',
            'Experience our core features at no cost.',
        ],
        priceId: ''
    },

    {
        id: 1,
        name: 'Auto Apply',
        resumes: 0,
        autoApply: 100,
        comments: 100,
        price: '$10',
        features: [
            '100 Auto Applies',
            '100 AI Post Comments (Free)',
            'Seamless Integration with LinkedIn'
        ],
        description: 'Use our advanced auto-apply feature to apply for 100 jobs with one click. Additionally, engage more on LinkedIn with 100 AI-generated comments.',
        highlights: [
            '50% increase in LinkedIn profile views reported by users.',
            '95% satisfaction rate among our customers.'
        ],
        priceId: 'price_1PHv5jSCIZ8MgjijPx7vmFgu'
    },
    {
        id: 2,
        name: 'Resumes',
        resumes: 100,
        autoApply: 0,
        comments: 100,
        price: '$20',
        features: [
            '100 Resume Generations',
            '100 AI Post Comments (Free)',
            'Access to Exclusive Resources'
        ],
        description: 'Generate 100 tailored resumes and boost your LinkedIn engagement with 100 AI-generated comments.',
        highlights: [
            'Make you an ideal candidate for each role, ensuring you stay in the top 1% of applicants.',
            '70% of users saw a significant increase in interview invitations.',
            '80% of users received interview calls within the first month.'
        ],
        priceId: 'price_1PHtanSCIZ8MgjijVuun7B8j'
    },
    {
        id: 3,
        name: 'Ultimate',
        resumes: 100,
        autoApply: 100,
        comments: 200,
        price: '$25',
        features: [
            '100 Resume Generations',
            '100 Auto Applies',
            '200 AI Post Comments',
            'Priority Email Support',
            'Access to All Premium Features'
        ],
        description: 'Get the best of both worlds with 100 tailored resumes, 100 auto applies, and 200 AI-generated comments. Optimize your job search and LinkedIn engagement effortlessly.',
        highlights: [
            '90% of users reported receiving interview calls within the first two weeks.',
            '60% increase in LinkedIn profile views on average.',
            'Over 95% of users saw a boost in job opportunities.',
            '97% customer satisfaction rate.'
        ],
        priceId: 'price_1PHuFjSCIZ8MgjijPx1abFst'
    }
];

const PricingSection = () => {
    const theme = useTheme();

    const cardVariants = {
        offscreen: { y: 30, opacity: 0 },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 10,
                delay: 0.2
            }
        }
    };
    return (
        <Box sx={{ padding: theme.spacing(8, 4) }} id="pricing">
            <Typography variant="h3" align="center" gutterBottom sx={{ mb: 4, fontFamily: 'Georgia, serif' }}>
                Pricing Plans
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ mb: 4, fontFamily: 'Georgia, serif', color: theme.palette.primary.main }}>
                Get a full refund if you don't receive an interview call within 2 weeks, no questions asked
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {plans.map((plan) => (
                    <Grid item xs={12} sm={6} md={3} key={plan.id}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={cardVariants}
                        >
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    padding: theme.spacing(2, 2, 4),
                                    backgroundColor: '#fff',
                                    height: '600px',
                                    color: theme.palette.text.primary,
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                    border: `1px solid ${theme.palette.text.primary}`,
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        transition: 'transform 0.3s ease-in-out',
                                    },
                                }}
                            >
                                <CardContent sx={{ fontFamily: 'Georgia, serif' }}>
                                    <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Georgia, serif' }}>
                                        {plan.name}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom align="center" sx={{ mb: 2, fontFamily: 'Georgia, serif' }}>
                                        {plan.price}
                                    </Typography>

                                    <Box sx={{ height: '80px' }}>
                                        {plan.autoApply > 0 && (
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', fontFamily: 'Georgia, serif' }}>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    Auto Applies:
                                                </Typography>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    {plan.autoApply}
                                                </Typography>
                                            </Box>
                                        )}
                                        {plan.resumes > 0 && (
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', fontFamily: 'Georgia, serif' }}>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    Resumes:
                                                </Typography>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    {plan.resumes}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', fontFamily: 'Georgia, serif', height: '40px' }}>
                                        <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                            Comments: (Free)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                            {plan.comments}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ height: '80px' }}>
                                        <Typography variant="body2" gutterBottom sx={{ mt: 2, fontFamily: 'Georgia, serif', color: theme.palette.text.secondary }}>
                                            {plan.description}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ height: '120px' }}>
                                        <Typography variant="body1" gutterBottom sx={{ mt: 2, fontFamily: 'Georgia, serif', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                            Highlights:
                                        </Typography>
                                        <Box sx={{ fontFamily: 'Georgia, serif' }}>
                                            {plan.highlights.map((highlight, idx) => (
                                                <Typography variant="body1" key={idx} sx={{ fontFamily: 'Georgia, serif', color: theme.palette.success.main }}>
                                                    • {highlight}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};


export default PricingSection;
