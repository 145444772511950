import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import '../../styles/generated_resumes.css';
import { baseurl, resumes_list, fetch_tailored_resume } from '../../util/endpoints';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { Box, useTheme, Typography } from '@mui/material';
import ResumeDataModal from './editGeneratedResume/resume_edit_dialog_modal.js';
import { generatePDF } from '../pdfFormatter'; // Import the new function
import CircularProgress from '@mui/material/CircularProgress';

const GeneratedResumes = () => {
    const [resumes, setResumes] = useState([]);
    const [tailoredResumeData, settailoredResumeData] = useState([]);
    const [resumeCount, setResumeCount] = useState('');
    const [loading, setLoading] = useState(true);
    const [userProfileData, setUserProfileData] = useState({});
    const userid = localStorage.getItem('userid');
    const [downloadDataByJobId, setDownloadDataByJobId] = useState({});
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        if (userid) {
            const fetchResumes = async () => {
                try {
                    const token = await getAccessTokenSilently();
                    const url = baseurl + resumes_list;
                    const response = await axios.post(url, { userid },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                            }
                        });
                    const sortedResumes = response.data.resumeList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setResumes(sortedResumes);
                    setResumeCount(response.data.resumeCount);
                    setUserProfileData(response.data.userProfileData);
                } catch (error) {
                    alert('Failed to fetch resumes. Please try again later.');
                } finally {
                    setLoading(false);
                }
            };
            fetchResumes();
        }
    }, [userid]);

    const fetchAndDownloadResume = async (jobId) => {
        try {
            const token = await getAccessTokenSilently();
            const url = baseurl + fetch_tailored_resume;
            const response = await axios.post(url, { userid, jobId },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
            const resumeData = response.data.resume_data;
            settailoredResumeData(resumeData);
            const educationFormatted = userProfileData.education.map(edu => ({
                institution_name: edu.institutionName,
                degree: edu.degree,
                field_of_study: edu.fieldOfStudy,
                start_date: edu.startDate.substring(0, 10),
                end_date: edu.endDate.substring(0, 10)
            }));

            const sortItemsByStartDate = (items) => {
                return items.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            };

            const experiencesSorted = sortItemsByStartDate(resumeData[0].response?.experiences || []);

            const projectsSorted = sortItemsByStartDate(resumeData
                .filter(item => {
                    console.log("Filtering item:", item);
                    return typeof item === 'object' && item.response && Array.isArray(item.response.projects);
                })
                .reduce((acc, item) => {
                    console.log("Processing item:", item);
                    if (Array.isArray(item.response.projects)) {
                        return acc.concat(item.response.projects.filter(project => typeof project === 'object'));
                    }
                    return acc;
                }, [])
            );

            const educationSorted = sortItemsByStartDate(educationFormatted);

            return {
                user_full_name: `${userProfileData.firstName} ${userProfileData.lastName}`,
                user_location: userProfileData.location_preference || '',
                user_phone: userProfileData.phone || '',
                user_email: userProfileData.email || '',
                user_linkedin: userProfileData.linkedin || '',
                portfolio_link: userProfileData.portfolio_link || '',
                github_link: userProfileData.github_link || '',
                experiences: experiencesSorted,
                projects: projectsSorted,
                education: educationSorted,
                certifications: userProfileData.certifications || [],
                generated_summary: resumeData[0].response?.generated_summary || '',
                skills: resumeData[0].response?.skills || {}
            };
        } catch (error) {
            alert('An error occurred while fetching resume details. Please try again later.');
        }
    };

    const fetchAndPrepareDataForDownload = async (jobId) => {
        try {
            const data = await fetchAndDownloadResume(jobId);
            setDownloadDataByJobId(prevState => ({
                ...prevState,
                [jobId]: {
                    isReady: prevState[jobId]?.isReady ? prevState[jobId].isReady : true,
                    data,
                }
            }));
            if (!isModalOpen) {
                setIsModalOpen(true);
            }
        } catch (error) {
            alert('Error processing resume');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        const day = date.getDate();
        const daySuffix = ['st', 'nd', 'rd'][(day % 10) - 1] || 'th';
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear().toString().slice(-2);

        return `${formattedHours}:${formattedMinutes} ${ampm} ${day}${daySuffix} ${month} ${year}`;
    };

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress 
                    size={60}
                    thickness={4}
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div className="resumes-list">
            <Typography sx={{
                fontSize: { xs: '1.5rem', sm: '1.75rem' },
                fontFamily: 'Georgia, serif',
                margin: '0 auto',
                textAlign: 'start',
                fontWeight: 'bold',
                color: theme.palette.text.secondary,
                marginBottom: '16px',
            }}>
                Total Generated Resumes: {resumeCount}
            </Typography>
            {loading ? (
                <Box sx={{ width: '100%' }}>
                    {Array.from(new Array(5)).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            animation="wave"
                            className="resume-skeleton"
                        />
                    ))}
                </Box>
            ) : (
                resumes.map((resume, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className="resume-card"
                    >
                        <div className="resume-header">
                            <h3 className="resume-title">{resume.jobTitle || 'Untitled Job'}</h3>
                            <span className="company-name">{resume.companyName || 'Unknown Company'}</span>
                        </div>
                        <div className="resume-footer">
                            <p className="generated-at">Generated: {formatDate(resume.createdAt)}</p>
                            <button
                                onClick={() => fetchAndPrepareDataForDownload(resume.jobId)}
                                style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
                            >
                                Edit or Download Resume
                            </button>
                        </div>
                        {downloadDataByJobId[resume.jobId]?.isReady && (
                            <ResumeDataModal
                                open={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                                data={downloadDataByJobId[resume.jobId].data}
                                jobId={resume.jobId}
                                userProfileData={userProfileData}
                            />
                        )}
                    </motion.div>
                ))
            )}
        </div>
    );
};

export default GeneratedResumes;