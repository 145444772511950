import React from 'react';
import { Container, Typography, Box, Link, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#ffffff',
  padding: theme.spacing(6, 4),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(4),
  maxWidth: '800px',
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#1976d2',
  color: '#fff',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fafafa',
  borderRadius: theme.spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  color: '#1976d2',
  marginBottom: theme.spacing(2),
}));

const TermsConditions = () => {
  return (
    <StyledContainer>
      <HeroSection>
        <Typography variant="h3" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="h6">
          Please read our terms and conditions carefully before using our services.
        </Typography>
      </HeroSection>
      <ContentBox>
        <Typography variant="body1" paragraph align="right">
          <em>Last Updated: October 15, 2024</em>
        </Typography>

        <SectionHeading variant="h5">1. Introduction</SectionHeading>
        <Typography variant="body1" paragraph>
          Welcome to Careerfornia ("we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of our website and services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with these Terms, you must not use our Services.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">2. Acceptance of Terms</SectionHeading>
        <Typography variant="body1" paragraph>
          By accessing or using Careerfornia, you acknowledge that you have read, understood, and agree to be bound by these Terms and our{' '}
          <Link href="/privacy-policy">Privacy Policy</Link>. These Terms constitute a legally binding agreement between you and Careerfornia.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">3. Modifications to Terms</SectionHeading>
        <Typography variant="body1" paragraph>
          We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our website. Your continued use of the Services after changes are posted constitutes your acceptance of the revised Terms. We encourage you to review these Terms periodically.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">4. Eligibility</SectionHeading>
        <Typography variant="body1" paragraph>
          You must be at least 18 years old or the age of majority in your jurisdiction to use our Services. By using our Services, you represent and warrant that you have the legal capacity to enter into these Terms.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">5. Account Registration and Security</SectionHeading>
        <Typography variant="body1" paragraph>
          <strong>5.1 Account Creation:</strong> You may be required to create an account to access certain features of our Services. You agree to provide accurate, current, and complete information during the registration process.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.2 Account Responsibility:</strong> You are responsible for safeguarding your account credentials and for any activities or actions under your account. You agree to notify us immediately of any unauthorized use of your account.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">6. Services Provided</SectionHeading>
        <Typography variant="body1" paragraph>
          Careerfornia provides tools and resources to assist users in career development, including but not limited to resume building and job search assistance. We use third-party applications and various AI models from different service providers to deliver these Services.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">7. User Responsibilities and Disclaimer</SectionHeading>
        <Typography variant="body1" paragraph>
          <strong>7.1 Compliance:</strong> You agree to use our Services in compliance with all applicable laws, regulations, and these Terms.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.2 User Content:</strong> You are solely responsible for any content you submit, upload, or transmit through our Services, including resumes and personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.3 Usage Limits:</strong> While our tools are powerful, we encourage you to use them within reasonable limits to ensure optimal performance for all users.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.4 Sole Responsibility:</strong> You acknowledge and agree that you are solely responsible for all outcomes resulting from your use of the platform. Careerfornia is not responsible for any consequences, direct or indirect, that may arise from your use of our Services, including but not limited to career decisions, job applications, or any actions taken based on the information or tools provided through our platform.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">8. Third-Party Services and AI Models</SectionHeading>
        <Typography variant="body1" paragraph>
          <strong>8.1 Third-Party Applications:</strong> We utilize third-party applications solely for the purpose of providing our Services. We do not sell or distribute your data to third parties for marketing or other purposes.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.2 AI Models:</strong> Our Services incorporate AI models from various service providers. We do not guarantee the accuracy or reliability of results generated by these models.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">9. Intellectual Property Rights</SectionHeading>
        <Typography variant="body1" paragraph>
          <strong>9.1 Ownership:</strong> All content, features, and functionality on our website—including but not limited to text, graphics, logos, and software—are the exclusive property of Careerfornia or its licensors and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.2 Limited License:</strong> We grant you a limited, non-exclusive, non-transferable license to access and use the Services for personal, non-commercial purposes.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">10. Prohibited Activities</SectionHeading>
        <Typography variant="body1" paragraph>
          You agree not to:
        </Typography>
        <ul>
          <li>Use the Services for any unlawful purpose.</li>
          <li>Reproduce, distribute, or publicly display any content from our Services without prior written consent.</li>
          <li>Interfere with or disrupt the integrity or performance of the Services.</li>
          <li>Attempt to gain unauthorized access to any portion of the Services or any other systems or networks connected to the Services.</li>
        </ul>

        <StyledDivider />

        <SectionHeading variant="h5">11. Disclaimers</SectionHeading>
        <Typography variant="body1" paragraph>
          <strong>11.1 "As-Is" Basis:</strong> The Services are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the Services, including but not limited to their reliability, accuracy, or suitability for a particular purpose.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.2 No Guarantee:</strong> We do not guarantee that using our Services will result in employment or career advancement.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.3 User Responsibility:</strong> You understand and agree that you are solely responsible for any outcomes, decisions, or actions resulting from your use of our Services. Careerfornia shall not be held liable for any consequences arising from your use of the platform.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">12. Limitation of Liability</SectionHeading>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.1 No Liability:</strong> Careerfornia and its officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.2 Resumes and Content:</strong> We are not responsible for any damages or issues that may arise from the resumes or content you submit or use through our Services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.3 User Outcomes:</strong> You expressly understand and agree that Careerfornia shall not be liable for any outcomes, decisions, or actions resulting from your use of our Services, including but not limited to career choices, job applications, or any other professional or personal decisions made based on the information or tools provided through our platform.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">13. Indemnification</SectionHeading>
        <Typography variant="body1" paragraph>
          You agree to indemnify, defend, and hold harmless Careerfornia and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">14. Privacy Policy and Data Usage</SectionHeading>
        <Typography variant="body1" paragraph>
          <strong>14.1 Data Collection:</strong> We collect and use analytics data to improve our Services. Please refer to our <Link href="/privacy-policy">Privacy Policy</Link> for information on how we collect, use, and disclose personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>14.2 No Data Selling:</strong> We do not sell your personal data to third parties.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">15. Governing Law and Jurisdiction</SectionHeading>
        <Typography variant="body1" paragraph>
          These Terms are governed by and construed in accordance with the laws of the State of Telangana, India, without regard to its conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located in Telangana for the resolution of any disputes.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">16. Entire Agreement</SectionHeading>
        <Typography variant="body1" paragraph>
          These Terms and our Privacy Policy constitute the entire agreement between you and Careerfornia regarding the use of our Services and supersede any prior agreements.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">17. Severability</SectionHeading>
        <Typography variant="body1" paragraph>
          If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">18. Waiver</SectionHeading>
        <Typography variant="body1" paragraph>
          No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Careerfornia's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">19. Assignment</SectionHeading>
        <Typography variant="body1" paragraph>
          You may not assign or transfer your rights or obligations under these Terms without our prior written consent. We may assign or transfer our rights and obligations to a third party without notice to you.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">20. Termination</SectionHeading>
        <Typography variant="body1" paragraph>
          We reserve the right to suspend or terminate your access to our Services at our sole discretion, without prior notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">21. Contact Information</SectionHeading>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email:</strong> <Link href="mailto:support@careerfornia.com">support@careerfornia.com</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Address:</strong> Careerfornia, Hyderabad, Telangana, India
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">22. Refund Policy</SectionHeading>
        <Typography variant="body1" paragraph>
          For information regarding refunds, please refer to our <Link href="/refunds">Refund Policy</Link>.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">23. Analytics and Cookies</SectionHeading>
        <Typography variant="body1" paragraph>
          We use analytics tools and cookies to enhance user experience and analyze website traffic. By using our Services, you consent to the use of cookies in accordance with our <Link href="/privacy-policy">Privacy Policy</Link>.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">24. Third-Party Links</SectionHeading>
        <Typography variant="body1" paragraph>
          Our Services may contain links to third-party websites or services that are not owned or controlled by Careerfornia. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">25. Force Majeure</SectionHeading>
        <Typography variant="body1" paragraph>
          We shall not be liable for any failure or delay in performance due to causes beyond our reasonable control, including but not limited to acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, or strikes.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">26. Notices</SectionHeading>
        <Typography variant="body1" paragraph>
          Any notices or other communications provided by us under these Terms will be given by email or by posting to the Services.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">27. No Agency Relationship</SectionHeading>
        <Typography variant="body1" paragraph>
          Nothing in these Terms shall be construed as creating an agency, partnership, joint venture, or employment relationship between you and Careerfornia.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h5">28. Feedback</SectionHeading>
        <Typography variant="body1" paragraph>
          We welcome feedback, comments, and suggestions for improvements to the Services. You can submit feedback by emailing us at{' '}
          <Link href="mailto:support@careerfornia.com">support@careerfornia.com</Link>. You grant us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicensable, and transferable license to use, modify, and incorporate your feedback into our products and services.
        </Typography>

        <StyledDivider />

        <Typography variant="body1" paragraph align="center">
          By using Careerfornia, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. You also understand and accept that you are solely responsible for all outcomes resulting from your use of our platform.
        </Typography>
      </ContentBox>
    </StyledContainer>
  );
};

export default TermsConditions;
